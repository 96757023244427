import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    SubTitle,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, SubTitle, Tooltip, Legend, Filler, annotationPlugin, zoomPlugin);

const datasetSettings= {
    borderWidth: 1,
    pointRadius: 0.1,
    pointHoverRadius: 5,
}

const datasetColors = [
    ['#B402FA7F', '#B402FA3F', '#B402FA00', '#B402FAFF'],
    ['#FF6A007F', '#FF6A003F', '#FF6A0000', '#FF6A00FF'],
];
const colorOffsets = [0, 0.5, 1]

const ChartJsForPrediction = ({ data = [] , property, name, subtitle, limiter = null, additionalCharts = [], additionalLabels = [], callComponent = false }) => {

    const chartRef = React.useRef(null);
    const [ options, setOptions ] = useState({});
    const [ chartData, setChartData ] = useState({labels: [], datasets: []});
    const actions = [
        {
            name: 'Zoom +10%',
            handler() {
                if (chartRef && chartRef.current) {
                    chartRef.current.zoom(1.1);
                }
            }
        },
        {
            name: 'Zoom -10%',
            handler() {
                if (chartRef && chartRef.current) {
                    chartRef.current.zoom(0.9);
                }
            },
        },
        {
            name: 'Zoom x +10%',
            handler() {
                if (chartRef && chartRef.current) {
                    chartRef.current.zoom({x: 1.1});
                }
            }
        },
        {
            name: 'Zoom x -10%',
            handler() {
                if (chartRef && chartRef.current) {
                    chartRef.current.zoom({x: 0.9});
                }
            },
        },
        {
            name: 'Reset zoom',
            handler() {
                if (chartRef && chartRef.current) {
                    chartRef.current.resetZoom();
                }
            }
        }
    ];
    const update = () => {
        const mainDataset = {
            label: name,
            data: data.map( item => item[property]),
            fill: true,
            borderColor: '#008FFBFF',
            tension: 0.2,
            backgroundColor: ({chart: {ctx}}) => {
                const gradient = ctx.createLinearGradient(0, 0, 0, 450);
                gradient.addColorStop(0, 'rgba(124,198,253,0.5)');
                gradient.addColorStop(0.5, 'rgba(0, 143, 251, 0.25)');
                gradient.addColorStop(1, 'rgba(0, 143, 251, 0)');
                return gradient;
            },
            ...datasetSettings
        }

        const additionalDatasets = additionalCharts.map(({ name, property }, idx) => ({
            label: name,
            data: data.map( item => item[property]),
            fill: true,
            borderColor: datasetColors[idx][datasetColors[idx].length - 1],
            tension: 0.2,
            backgroundColor: ({chart: {ctx}}) => {
                const gradient = ctx.createLinearGradient(0, 0, 0, 450);
                for(let c = 0; c < datasetColors[idx].length - 1; c++) {
                    gradient.addColorStop(colorOffsets[c], datasetColors[idx][c]);
                }
                return gradient;
            },
            ...datasetSettings
        }));

        let max = Math.max(...mainDataset.data);
        max = max > 0 ? max : 5;
        max = limiter && limiter > max ? limiter : max;

        additionalDatasets.forEach(
            serie => serie.data.forEach( value => max = value > max ? value : max)
        );

        let min = Math.min(...mainDataset.data);
        additionalDatasets.forEach(
            serie => serie.data.forEach( value => min = value < min ? value : min)
        );
        min = min > 0 ? 0 : min;

        const statusDataset = {
            label: "Algorithm Status",
            data: data.map( item => item.status ? max * 1.2: null),
            fill: true,
            borderColor: '#9CD59CFF',
            tension: 0.2,
            backgroundColor: ({chart: {ctx}}) => {
                const gradient = ctx.createLinearGradient(0, 0, 0, 450);
                gradient.addColorStop(0, 'rgba(156, 213, 156, 0.5)');
                gradient.addColorStop(0.5, 'rgba(156, 213, 156, 0.25)');
                gradient.addColorStop(1, 'rgba(156, 213, 156, 0)');
                return gradient;
            },
            ...datasetSettings
        };

        const datasets = [];
        if (!callComponent) datasets.push(statusDataset);
        datasets.push(...additionalDatasets);
        datasets.push(mainDataset);

        const chartData = {
            labels: data.map( item => item.created_at ?? item.time ),
            datasets
        };

        const options = {
            interaction: {
                intersect: false,
                mode: 'index',
            },
            responsive: true,
            plugins: {
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'xy',

                    },
                    pan: {
                        enabled: true
                    },
                    limits: {
                        y: {
                            min,
                            max: max * 2,
                            minRange: max / 2
                        },
                    },

                },
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            borderColor: '#00E396',
                            borderWidth: 1,
                            label: {
                                backgroundColor: '#00E396',
                                borderColor: '#00E396',
                                borderRadius: 5,
                                borderWidth: 1,
                                content: 'Maximum',
                                position: 'end',
                                display: true
                            },
                            scaleID: 'y',
                            value: limiter,
                        }
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            if(context.datasetIndex === 0){
                                let label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    if (callComponent) {
                                        label +=  context.raw > 0 ? context.raw : 0;    
                                    } else {
                                        label = context.raw > 0 ? 'Algorithm enabled' : 'Disabled';
                                    }
                                    
                                }
                                return label;
                            }
                        },
                        footer: (item) => {
                            return additionalLabels.reduce((acc, label) => {
                                if (
                                    item[0] &&
                                    data[item[0].dataIndex]
                                ) {
                                    const value = typeof label.property === 'function'
                                        ? label.property(data[item[0].dataIndex])
                                        : data[item[0].dataIndex][label.property];

                                    acc.push(`${label.name}: ${value !== undefined ? value : 'No data'}`);
                                }

                                return acc;
                            }, []);
                        }
                    },
                },
                title: {
                    display: true,
                    text: name,
                    align: 'center'
                },
                subtitle: {
                    display: true,
                    text: subtitle
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    max: max * 1.2
                }
            }
        };
        setOptions(options)
        setChartData(chartData)
    }

    useEffect(() => {
        update()
    }, [ data ]);

    return (
        <div>
            {
                <Line
                    ref={chartRef}
                    height={350}
                    width={900}
                    data={chartData}
                    options={options}
                />
            }
            <div style={{ display: 'flex',justifyContent: 'space-around'}}>
                {
                    actions.map((action) => (
                        <button
                            key={action.name}
                            onClick={action.handler}
                            className="prediction-stat-button"
                        >
                            {action.name}
                        </button>
                    ))
                }
            </div>
        </div>
    );
}

export default ChartJsForPrediction;
