import axios from "axios";
import React, { useEffect, useState } from 'react';
import './style.comp.css';
import {ToastContainer, toast} from 'react-toastify';
import {connect} from "react-redux";
import {
    Checkbox,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead, TextField
} from "@material-ui/core";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const AgentRotation = (props) => {
    const {isFetching} = props;
    const rotations = [ 0, 1, 2, 3, 4, 5, 6, "7-12" ];
    const [ order, setOrder ] = useState({ field: "transfer_rank", asc: "desc" });
    const [ agentRotations, setAgentRotations ] = useState([]);
    const [ showAvailableOnly, setShowAvailableOnly ] = useState(false);
    const [ updated, setUpdated ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ refreshInterval, setRefreshInterval ] = useState(null);

    const fetchAgentRotations = async () => {
        setLoading(true);
        const agentRotations = await axios
            .get(`/buffer/agent/rotation?orderBy=${order.field}&orderAsc=${order.asc}&availableOnly=${showAvailableOnly ? 1 : 0}`)
            .then( ({ data }) => data)
            .catch( () => []);

        setAgentRotations(agentRotations);
        setUpdated(false);
        setLoading(false);
    }

    const saveAllowed = () => updated && ! loading;

    const save = async () => {
        if (! saveAllowed()) return;

        setLoading(true);
        const data = agentRotations.reduce( (acc, agent) => {
            acc[agent.id] = {
                rotations: agent.rotations,
                transfer_rank: agent.transfer_rank
            };
            return acc;
        }, {});

        await axios
            .post("/buffer/agent/rotation", data)
            .then(() => {
                toast.success("Agents rotations have been updated");
                setUpdated(false);
            })
            .catch(error => toast.warn(error.response.data));
        setLoading(false);
    }

    const toggleRotation = (index, rotation) => {
        if (loading) return;

        const rotations = JSON.parse(JSON.stringify(agentRotations));
        const enabled = rotations[index].rotations.includes(rotation);
        if (enabled) {
            rotations[index].rotations = rotations[index].rotations.filter( item => item !== rotation);
        } else {
            rotations[index].rotations.push(rotation);
        }

        setAgentRotations(rotations);
        setUpdated(true);
    }

    const changeOrdering = field => {
        if (loading) return;

        if (order.field === field) {
            setOrder({ field, asc: order.asc === "asc" ? "desc": "asc" })
            return;
        }

        setOrder({ field, asc: "asc" });
    }

    const changeTransferRank = (index, value) => {
        value = Number(Number(value).toFixed(2));
        if (isNaN(value) || value < 0 || value > 999) return;

        const rotations = JSON.parse(JSON.stringify(agentRotations));
        rotations[index].transfer_rank = value;
        setAgentRotations(rotations);
        setUpdated(true);
    }

    const selectAll = rotation => {
        if (loading) return;

        const rotations = JSON.parse(JSON.stringify(agentRotations));
        const shouldAllBeEnabled = Object.values(rotations).find( agent => ! agent.rotations.includes(rotation) );

        for (const agent of rotations) {
            if (shouldAllBeEnabled && ! agent.rotations.includes(rotation)) {
                agent.rotations.push(rotation);
            }

            if (! shouldAllBeEnabled) {
                agent.rotations = agent.rotations.filter( item => item !== rotation );
            }
        }

        setAgentRotations(rotations);
        setUpdated(true);
    }
    const clearRefresh = () => {
        if (! refreshInterval) return;

        clearInterval(refreshInterval);
        setRefreshInterval(null);
    }
    const startRefresh = () => setRefreshInterval(setInterval(fetchAgentRotations, 5_000));

    useEffect(async() => {
        clearRefresh();
        await fetchAgentRotations();
        if (updated === false) {
            startRefresh(isFetching, showAvailableOnly, order);
        }
    }, [ isFetching, showAvailableOnly, order ]);

    useEffect(() => {
        clearRefresh();
        if (! updated) {
            startRefresh(isFetching, showAvailableOnly, order);
        }
    }, [ updated ]);

    useEffect(() => {
        return () => clearRefresh();
    }, []);

    return (
        <div className="agent-rotation">
            <div style={{ maxWidth: '1520px', paddingTop:'2rem', fontSize:'1.5rem', display:'flex', justifyContent:'space-between', alignItems: "center", margin: 'auto'}} >
                <div>
                    <h2>Agents Ranking System <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>{refreshInterval ? '(Auto refresh)' : ''}</span></h2>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                            Show available only
                            <Checkbox onChange={ (e) => setShowAvailableOnly(! showAvailableOnly) }
                                      checked={ showAvailableOnly }
                                      style={{ color: '#07b839' }}
                            />
                        </label>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button className="save-button"
                            style={{ width: "150px" }}
                            disabled={ ! saveAllowed() }
                            onClick={ save }
                    >{ loading? "Loading..." : "Save"}</button>
                </div>
            </div>

            <div className="call-timeouts-table" style={{ maxWidth: '1600px', padding:'2rem', fontSize:'1.5rem', display:'flex', justifyContent:'center', margin: 'auto'}} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', userSelect: "none" }}>
                                Index
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("id") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'id' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'id' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    ID
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("name") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'name' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'name' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Agent name
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("call_center") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'call_center' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'call_center' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Call center
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("online") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'online' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'online' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Online
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("on_call") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'on_call' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'on_call' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    On call
                                </div>
                            </TableCell>
                            <TableCell style={{ width: "150px" }} onClick={ () => changeOrdering("transfer_rank") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'transfer_rank' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'transfer_rank' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Transfer rank
                                </div>
                            </TableCell>
                            <TableCell style={{ width: "150px" }} onClick={ () => changeOrdering("statistical_transfer_rank") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'statistical_transfer_rank' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'statistical_transfer_rank' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Statistical transfer rank
                                </div>
                            </TableCell>
                            {rotations.map( rotation => (
                                <TableCell style={{ fontWeight: 'bold', textAlign: "center", cursor: "pointer", userSelect: "none" }}
                                           className="tooltip-target"
                                           key={rotation}
                                           onClick={ () => selectAll(rotation) }
                                >
                                    R{rotation} {rotation === 0 ? "< 60sec" : ""}
                                    <div className="tooltip">
                                        Click to toggle all rotation
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agentRotations.map( (agentRotation, index) => (
                            <TableRow key={agentRotation.id}>
                                <TableCell style={{ textAlign: "center" }}>{index + 1}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentRotation.id}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentRotation.name}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentRotation.call_center}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentRotation.online ? "Yes" : "No"}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentRotation.on_call ? "Yes" : "No"}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                    <TextField type="number"
                                               className="transfer-rank-field"
                                               value={agentRotation.transfer_rank}
                                               inputMode='numeric'
                                               onChange={({ target }) => changeTransferRank(index, target.value)}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentRotation.statistical_transfer_rank.toFixed(2)}</TableCell>
                                {rotations.map( rotation => (
                                    <TableCell key={agentRotation.id + "_" + rotation}
                                               style={{ backgroundColor: "#efefef", textAlign: "center" }}
                                    >
                                        <Checkbox onChange={ (e) => toggleRotation(index, rotation) }
                                                  disabled={ loading }
                                                  checked={ agentRotation.rotations.includes(rotation) }
                                                  style={{ color: '#07b839' }}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default connect(mapStateToProps)(AgentRotation);
