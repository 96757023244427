import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './style.comp.css';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import ChartJsForPrediction from "./ChartJsForPrediction";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const formatRotationLabels = () => Array
    .from({ length: 12 }, (_, i) => i + 1)
    .map(name => ({
        name: `R${name}`,
        property: row => row.transfer_rate_per_rotation[name] || "No data"
    }));

const charts = [
    {
        name: "Headroom Utilization",
        subtitle: "Average headroom utilization during period",
        property: "utilization"
    },
    {
        name: "Total headroom",
        subtitle: "Average total and available headroom during period",
        property: "total_headroom",
        additionalCharts: [
            {
                name: "Available headroom",
                property: "available_headroom"
            }
        ],
    },
    {
        name: "Calls count",
        subtitle: "Amount of A leg calls during period",
        property: "calls_count",
        additionalCharts: [
            {
                name: "Transfers count",
                property: "transfers_count"
            },
        ],
        additionalLabels: [
            {
                name: 'Utilization',
                property: row => `${row.utilization}%`
            },
            {
                name: 'Transfer rates',
                property: () => ``
            },
            ...formatRotationLabels()
        ]
    },
    {
        name: "Hangups count",
        subtitle: "Amount of hangups received during period",
        property: "hangups_count"
    },
    {
        name: "Flex count",
        subtitle: "Amount of flexes added during period",
        property: "flex_count"
    },
    {
        name: "Time in queue",
        subtitle: "Average time in queue (sec)",
        property: "average_time_in_queue"
    }
];

const PredictionKPI = (props) => {
    const { isFetching } = props;
    const [chartData, setChartData] = useState([]);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [isLoading, setIsLoading] = useState(false);

    const handleDayChange = (date) => {
        const selectedDate = moment(date).format('YYYY-MM-DD');
        setDate(selectedDate);
    };

    const fetchDataForSelectedDay = useCallback(async (selectedDate) => {
        try {
            setIsLoading(true);
            const data = await axios
                .get(`/buffer/statistic/predictions/kpi?date=${selectedDate}`)
                .then(({ data }) => Array.isArray(data) ? data : [])
                .catch(error => {
                    if (error.response && error.response.data && typeof error.response.data === 'object') {
                        let warn = "";
                        for (const key in error.response.data) {
                            const message = error.response.data[key];
                            warn += message;
                        }
                        toast.warn(warn);
                    }
                    return [];
                });
    
            setChartData(data.map(item => ({
                ...item,
                average_time_in_queue: Math.round(item.average_time_in_queue),
                utilization: Math.round(item.utilization),
                created_at: moment(item.created_at).format("H:00")
            })));
            setIsLoading(false);
    
        } catch (error) {
            toast.error('Data not available');
            console.log(error);
            setIsLoading(false);
            setChartData([]);
        }
    
    }, []);

    useEffect(() => {
        fetchDataForSelectedDay(date);
    }, [fetchDataForSelectedDay, date]);

    return (
        <div className="chart-container">
            <div className="previous_dates-container" style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: "20px",
                padding: "20px"
            }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        label="Select date"
                        value={date}
                        onChange={handleDayChange}
                        maxDate={new Date()}
                        format="YYYY-MM-DD"
                        clearable
                        autoOk
                    />
                </MuiPickersUtilsProvider>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {
                    isLoading ? <h2 style={{ margin: '0 auto'}}>Loading....</h2> :
                    (charts.map( chart =>
                        <ChartJsForPrediction
                            key={chart.property}
                            data={chartData}
                            name={chart.name}
                            subtitle={chart.subtitle}
                            property={chart.property}
                            additionalCharts={chart.additionalCharts || []}
                            additionalLabels={chart.additionalLabels || []}
                        />
                    ))
                }
            </div>


            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default connect(mapStateToProps)(PredictionKPI);
