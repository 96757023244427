import React, { useEffect, useState } from 'react';
import './style.comp.css';
import { socket } from '../socket';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import moment from "moment";
import {
    Table,
    TableBody,
    TableRow,
    TableCell
} from "@material-ui/core";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const AvailabilityStatistic = (props) => {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [companies, setCompanies] = useState(null);
    const [companiesFetchTime, setCompaniesFetchTime] = useState(null);
    const [leads, setLeads] = useState(null);
    const [leadsFetchTime, setLeadsFetchTime] = useState(null);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
            toast.success("Connected");
        }

        function onDisconnect() {
            setIsConnected(false);
            toast.warn("Disconnected");
        }

        function onCompanies(data) {
            setCompanies(data);
            setCompaniesFetchTime(moment().format("HH:mm:ss DD/MM/YYYY"));
        }

        function onLeads(data) {
            setLeads(data);
            setLeadsFetchTime(moment().format("HH:mm:ss DD/MM/YYYY"));
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('statistic/leadsAvailability', onLeads);
        socket.on('statistic/companiesAvailability', onCompanies);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('statistic/leadsAvailability', onLeads);
            socket.off('statistic/companiesAvailability', onCompanies);
        };
    }, []);


    return (
        <div className="available_statistic__wrapper">
            <div style={{ marginLeft: '200px', marginRight: '200px', display: 'flex' }}>
                <div>
                    <Table style={{ width: '800px', marginRight: '50px' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell colSpan={4} style={{ fontWeight: 'bold' }}>Companies</TableCell>
                                <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>{ companiesFetchTime }</TableCell>
                            </TableRow>
                            {
                                companies && <>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Total</TableCell>
                                        <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>Available</TableCell>
                                        <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>{ companies.total }</TableCell>
                                        <TableCell colSpan={2}>{ companies.available }</TableCell>
                                        <TableCell colSpan={2}>{ companies.unavailable }</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colspan={4} align="center" style={{ fontWeight: 'bold' }}>Raw</TableCell>
                                        <TableCell colspan={4} align="center" style={{ fontWeight: 'bold' }}>Warm</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Total</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Available</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                        <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Total</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Available</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>{ companies.raw.total }</TableCell>
                                        <TableCell align="center">{ companies.raw.available }</TableCell>
                                        <TableCell align="center">{ companies.raw.unavailable }</TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>{ companies.warm.total }</TableCell>
                                        <TableCell align="center">{ companies.warm.available }</TableCell>
                                        <TableCell align="center">{ companies.warm.unavailable }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Qualifier</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Available</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                        <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Qualifier</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Available</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>Balance</TableCell>
                                        <TableCell>{ companies.raw.balance.available }</TableCell>
                                        <TableCell>{ companies.raw.balance.unavailable }</TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>Balance</TableCell>
                                        <TableCell>{ companies.warm.balance.available }</TableCell>
                                        <TableCell>{ companies.warm.balance.unavailable }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>Call counts</TableCell>
                                        <TableCell>{ companies.raw.call_counts.available }</TableCell>
                                        <TableCell>{ companies.raw.call_counts.unavailable }</TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>Call counts</TableCell>
                                        <TableCell>{ companies.warm.call_counts.available }</TableCell>
                                        <TableCell>{ companies.warm.call_counts.unavailable }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>Daily capacity</TableCell>
                                        <TableCell>{ companies.raw.daily_cap.available }</TableCell>
                                        <TableCell>{ companies.raw.daily_cap.unavailable }</TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>Daily capacity</TableCell>
                                        <TableCell>{ companies.warm.daily_cap.available }</TableCell>
                                        <TableCell>{ companies.warm.daily_cap.unavailable }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>Hours</TableCell>
                                        <TableCell>{ companies.raw.hours.available }</TableCell>
                                        <TableCell>{ companies.raw.hours.unavailable }</TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: "left" }}>Hours</TableCell>
                                        <TableCell>{ companies.warm.hours.available }</TableCell>
                                        <TableCell>{ companies.warm.hours.unavailable }</TableCell>
                                    </TableRow>
                                </>
                            }
                            {! companies && <TableRow>
                                <td colSpan={8} style={{ textAlign: "center" }}>Waiting for data...</td>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
                <div>
                    <Table style={{ width: '800px', marginLeft: '50px' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell colSpan={4} style={{ fontWeight: 'bold' }}>Leads</TableCell>
                                <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>{ leadsFetchTime }</TableCell>
                            </TableRow>
                            { leads && <>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Total</TableCell>
                                    <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>Available</TableCell>
                                    <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: 'left' }}>{ leads.total }</TableCell>
                                    <TableCell colSpan={2}>{ leads.available }</TableCell>
                                    <TableCell colSpan={2}>{ leads.unavailable }</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colspan={4} align="center" style={{ fontWeight: 'bold' }}>Local</TableCell>
                                    <TableCell colspan={4} align="center" style={{ fontWeight: 'bold' }}>Long distance</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Total</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Available</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                    <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Total</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Available</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>{ leads.local.total }</TableCell>
                                    <TableCell align="center">{ leads.local.available }</TableCell>
                                    <TableCell align="center">{ leads.local.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>{ leads.longDistance.total }</TableCell>
                                    <TableCell align="center">{ leads.longDistance.available }</TableCell>
                                    <TableCell align="center">{ leads.longDistance.unavailable }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Qualifier</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Available</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                    <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Qualifier</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Available</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Unavailable</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Rotation</TableCell>
                                    <TableCell>{ leads.local.available_by_rotation.available }</TableCell>
                                    <TableCell>{ leads.local.available_by_rotation.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Rotation</TableCell>
                                    <TableCell>{ leads.longDistance.available_by_rotation.available }</TableCell>
                                    <TableCell>{ leads.longDistance.available_by_rotation.unavailable }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Multi transfer</TableCell>
                                    <TableCell>{ leads.local.available_multi_transfer.available }</TableCell>
                                    <TableCell>{ leads.local.available_multi_transfer.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Multi transfer</TableCell>
                                    <TableCell>{ leads.longDistance.available_multi_transfer.available }</TableCell>
                                    <TableCell>{ leads.longDistance.available_multi_transfer.unavailable }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Callback</TableCell>
                                    <TableCell>{ leads.local.callback_scheduled.available }</TableCell>
                                    <TableCell>{ leads.local.callback_scheduled.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Callback</TableCell>
                                    <TableCell>{ leads.longDistance.callback_scheduled.available }</TableCell>
                                    <TableCell>{ leads.longDistance.callback_scheduled.unavailable }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Handler</TableCell>
                                    <TableCell>{ leads.local.handler.available }</TableCell>
                                    <TableCell>{ leads.local.handler.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Handler</TableCell>
                                    <TableCell>{ leads.longDistance.handler.available }</TableCell>
                                    <TableCell>{ leads.longDistance.handler.unavailable }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Local time</TableCell>
                                    <TableCell>{ leads.local.local_time.available }</TableCell>
                                    <TableCell>{ leads.local.local_time.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Local time</TableCell>
                                    <TableCell>{ leads.longDistance.local_time.available }</TableCell>
                                    <TableCell>{ leads.longDistance.local_time.unavailable }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Next dial time</TableCell>
                                    <TableCell>{ leads.local.next_dial_time.available }</TableCell>
                                    <TableCell>{ leads.local.next_dial_time.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Next dial time</TableCell>
                                    <TableCell>{ leads.longDistance.next_dial_time.available }</TableCell>
                                    <TableCell>{ leads.longDistance.next_dial_time.unavailable }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Phone score</TableCell>
                                    <TableCell>{ leads.local.phone_score.available }</TableCell>
                                    <TableCell>{ leads.local.phone_score.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Phone score</TableCell>
                                    <TableCell>{ leads.longDistance.phone_score.available }</TableCell>
                                    <TableCell>{ leads.longDistance.phone_score.unavailable }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Serve time</TableCell>
                                    <TableCell>{ leads.local.serve_time.available }</TableCell>
                                    <TableCell>{ leads.local.serve_time.unavailable }</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: "left" }}>Serve time</TableCell>
                                    <TableCell>{ leads.longDistance.serve_time.available }</TableCell>
                                    <TableCell>{ leads.longDistance.serve_time.unavailable }</TableCell>
                                </TableRow>
                            </>}
                            {! leads && <TableRow>
                                <td colSpan={8} style={{ textAlign: "center" }}>Waiting for data...</td>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default connect(mapStateToProps)(AvailabilityStatistic);
