import React, { useEffect, useState } from 'react';
import './style.comp.css';
import { socket } from '../socket';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import moment from "moment";
import {
    Table,
    TableBody,
    TableRow,
    TableHead
} from "@material-ui/core";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const PredictionsStatistic = (props) => {
    const {isFetching} = props;
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [prediction, setPrediction] = useState(null);

    const formatters = {
        status: status => (<span className="prediction-value-item">{status ? "Enabled" : "Disabled"}</span>),
        rotations: rotations => {
            rotations = JSON.parse(rotations);
            return Object.keys(rotations).map(rotation => (
                <div>{rotation}: {rotations[rotation].count} ({rotations[rotation].percentage}%)</div>
            ));
        },
        createdAt: time => (<span className="prediction-value-item">{moment(time).format("HH:mm:ss DD.MM.YYYY")}</span>)
    }

    const formatName = name => {
        const names = {
            "predictedFinishedCallsInNextPeriod": "B leg calls",
            "aLegHeadroom": "A leg calls",
        }

        if (names[name]) return names[name];

        name = name.replace(/([A-Z])/g, " $1").trim().toLowerCase();
        return name.slice(0, 1).toUpperCase() + name.slice(1);
    }

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
            toast.success("Connected");
        }

        function onDisconnect() {
            setIsConnected(false);
            toast.warn("Disconnected");
        }

        function onPrediction(prediction) {
            const ordering = [
                "status",
                "createdAt",
                "availableHeadroom",
                "predictedFinishedCallsInNextPeriod",
                "aLegHeadroom",
                "queue",
                "predictedHeadroom",
                "agents",
                "velocity",
                "totalHeadroom",
                "progression",
                "neuralAvailableHeadroom",
                "acceleration",
                "velocityLimiter",
                "agentVelocity",
                "onlineAgents",
                "onlineAgentsOnCall",
                "rawCallsPerPeriod",
                "availableLongDistanceLeads",
                "callsPerPeriod",
                "finishedCalls",
                "masterEffect",
                "rotations",
            ];

            const newPrediction = ordering.reduce((acc, key) => {
                acc[key] = prediction[key];
                return acc;
            }, {});
            setPrediction(newPrediction);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('prediction', onPrediction);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('prediction', onPrediction);
        };
    }, []);


    return (
        <div className="prediction-statistic__wrapper">
            <Table style={{ width: '700px', marginLeft: '200px', marginRight: '200px' }}>
                <TableHead>
                    <TableRow style={{ borderBottom: "1px solid black" }}>
                        <th>Parameter</th>
                        <th>Value</th>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prediction && Object.keys(prediction).map(name => <TableRow className="prediction-metric-row">
                        <td className="prediction-metric">{formatName(name)}</td>
                        <td className="prediction-value">{
                            formatters[name] ? formatters[name](prediction[name]) : (<span className="prediction-value-item">{prediction[name]}</span>)
                        }</td>
                    </TableRow>)}
                    {! prediction && <TableRow>
                        <td colSpan={2} style={{ textAlign: "center" }}>Waiting for data...</td>
                    </TableRow>}
                </TableBody>
            </Table>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default connect(mapStateToProps)(PredictionsStatistic);
