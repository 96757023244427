import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './style.comp.css';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {Table, TableBody, TableCell, TableRow, TextField} from "@material-ui/core";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const PredictionReport = (props) => {
    const { isFetching } = props;
    const [isLoading, setIsLoading] = useState(false);

    const [dataSet, setDataSet] = useState({data: {}, date: null, toTime: null, fromTime: null});
    const [dataSetTwo, setDataSetTwo] = useState({data: {}, date: null, toTime: null, fromTime: null});
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateTwo, setDateTwo] = useState(moment().format('YYYY-MM-DD'));
    const [toTime, setToTime] = useState(moment().set({ hour: 13, minute: 0 }));
    const [fromTime, setFromTime] = useState(moment().set({ hour: 10, minute: 0 }));

    const handleAcceptFromTime = (time) => {
        setFromTime(time);
    };
    const handleAcceptToTime = (time) => {
        setToTime(time);
    };
    const handleDayChange = (date) => {
        const selectedDate = moment(date).format('YYYY-MM-DD');
        setDate(selectedDate);
    };
    const handleDayTwoChange = (date) => {
        const selectedDate = moment(date).format('YYYY-MM-DD');
        setDateTwo(selectedDate);
    };
    const getData = async (date, from, to, setWebhook) => {
        try {
            return await axios
                .get(`/buffer/statistic/predictions/report?date=${date}&from=${from.format("HH:mm")}&to=${to.format("HH:mm")}`)
                .then(({ data }) => data || {})
                .catch(error => {
                    if (error.response && error.response.data && typeof error.response.data === 'object') {
                        let warn = "";
                        for (const key in error.response.data) {
                            const message = error.response.data[key];
                            warn += message;
                        }
                        toast.warn(warn);
                    }
                    return {};
                });
        } catch (error) {
            toast.error('Data not available');
            console.log(error);
            setIsLoading(false);
            setWebhook({});
        }
    };
  
    const renderPercentDiffCell = (left, right) => {
        let percentage = Number((((right - left) / left) * 100).toFixed(2));
        let className = '';

        if (isNaN(percentage)) {
            percentage = 0;
        }
        switch (true) {
            case percentage < 0: className = 'ml-10 negative'; break;
            case percentage > 0: className = 'ml-10 positive'; break;
            default: className = 'ml-10';
        }
        percentage = percentage > 0 ? `+${percentage}` : percentage;

        return (
            <span className={className}>
                ({percentage + '%'})
            </span>
        );
    };

    const fetchData = useCallback(async (selectedFirstDate, selectedSecondDate, fromTime, toTime) => {
        setIsLoading(true);

        if(dataSet.date !== selectedFirstDate || dataSet.fromTime !== fromTime || dataSet.toTime !== toTime) {
            const data = await getData(selectedFirstDate, fromTime, toTime, setDataSet);
            setDataSet({data: data, date: selectedFirstDate, fromTime, toTime});
        }

        if(dataSetTwo.date !== selectedSecondDate || dataSetTwo.fromTime !== fromTime || dataSetTwo.toTime !== toTime) {
            const data = await getData(selectedSecondDate, fromTime, toTime, setDataSetTwo);
            setDataSetTwo({data: data, date: selectedSecondDate, fromTime: fromTime, toTime: toTime});
        }
        setIsLoading(false);

    }, []);

    useEffect(() => {
        fetchData(date, dateTwo, fromTime, toTime);
    }, [fetchData, date, dateTwo, fromTime, toTime, isFetching]);

    return (
        <div className="chart-container">
            <div className="previous_dates-container" style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                margin: "20px",
                padding: "20px"
            }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        label="Select First Date"
                        value={date}
                        onChange={handleDayChange}
                        maxDate={new Date()}
                        format="YYYY-MM-DD"
                        clearable
                        autoOk
                    />
                </MuiPickersUtilsProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        label="From"
                        ampm={false}
                        views={["hours", "minutes"]}
                        maxTime={toTime}
                        value={fromTime}
                        onAccept={handleAcceptFromTime}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        label="To"
                        ampm={false}
                        views={["hours", "minutes"]}
                        value={toTime}
                        onAccept={handleAcceptToTime}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        label="Select Second Date"
                        value={dateTwo}
                        onChange={handleDayTwoChange}
                        maxDate={new Date()}
                        format="YYYY-MM-DD"
                        clearable
                        autoOk
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className="prediction-report__wrapper">
                <div style={{ marginLeft: '200px', marginRight: '200px', display: 'flex' }}>
                    {
                        isLoading ? <h2 style={{ margin: '0 auto'}}>Loading....</h2> : <>
                    <div>
                        <Table style={{ width: '1000px' }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Metrics</TableCell>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>{date + '  ' + moment(fromTime).format('HH:mm') + ' - ' + moment(toTime).format('HH:mm')}</TableCell>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>{dateTwo + '  ' + moment(fromTime).format('HH:mm') + ' - ' + moment(toTime).format('HH:mm')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Algorithm working time</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.average_status !== null ? `${dataSet.data.average_status}%` : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.average_status !== null ? `${dataSetTwo.data.average_status}%`: 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.average_status, dataSetTwo.data.average_status)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Capacity</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data?.total_headroom !== null ? dataSet.data.total_headroom : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.total_headroom !== null ? dataSetTwo.data.total_headroom : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.total_headroom, dataSetTwo.data.total_headroom)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Total Calls</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.total_calls !== null ? dataSet.data.total_calls : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.total_calls !== null ? dataSetTwo.data.total_calls : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.total_calls, dataSetTwo.data.total_calls)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Total Hangups</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.total_hangups !== null ? dataSet.data.total_hangups : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.total_hangups !== null ? dataSetTwo.data.total_hangups : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.total_hangups, dataSetTwo.data.total_hangups)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Total Transfers</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.total_transfers !== null ? dataSet.data.total_transfers : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.total_transfers !== null ? dataSetTwo.data.total_transfers : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.total_transfers, dataSetTwo.data.total_transfers)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Total Flexed</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.total_flexed !== null ? dataSet.data.total_flexed : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.total_flexed !== null ? dataSetTwo.data.total_flexed : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.total_flexed, dataSetTwo.data.total_flexed)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Average Headroom</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.avg_available_headroom !== null ? dataSet.data.avg_available_headroom : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.avg_available_headroom !== null ? dataSetTwo.data.avg_available_headroom : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.avg_available_headroom, dataSetTwo.data.avg_available_headroom)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Average Rotation</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.avg_rotation !== null ? dataSet.data.avg_rotation : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.avg_rotation !== null ? dataSetTwo.data.avg_rotation : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.avg_rotation, dataSetTwo.data.avg_rotation)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Average Online Agents</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.avg_online_agents !== null ? dataSet.data.avg_online_agents : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.avg_online_agents !== null ? dataSetTwo.data.avg_online_agents : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.avg_online_agents, dataSetTwo.data.avg_online_agents)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>Total of new LD Leads</TableCell>
                                    <TableCell colSpan={1}>{dataSet.data.total_leads !== null ? dataSet.data.total_leads : 'N/A'}</TableCell>
                                    <TableCell colSpan={1}>
                                        {dataSetTwo.data.total_leads !== null ? dataSetTwo.data.total_leads : 'N/A'}
                                        {renderPercentDiffCell(dataSet.data.total_leads, dataSetTwo.data.total_leads)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div> </>
                    }
                </div>
            </div>


            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default connect(mapStateToProps)(PredictionReport);






