import React, { useEffect, useState } from 'react';
import './style.comp.css';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import moment from "moment";
import {
    Table,
    TableBody,
    TableRow,
    TableCell
} from "@material-ui/core";
import { backendSocket } from "../socket";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const AgentsStatistic = (props) => {
    const [isConnected, setIsConnected] = useState(backendSocket.connected);
    const [agentsStatistic, setAgentsStatistic] = useState(null);
    const [agentsStatisticFetchTime, setAgentsStatisticFetchTime] = useState(null);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
            backendSocket.emit("statistic");
            toast.success("Connected");
        }

        function onDisconnect() {
            setIsConnected(false);
            toast.warn("Disconnected");
        }

        function onStatistic(statistic) {
            setAgentsStatistic({
                statistic: statistic.statistic,
                perUser: Object.values(statistic.perUser)
            });
            setAgentsStatisticFetchTime(moment().format("HH:mm:ss DD.MM.YYYY"));
        }

        backendSocket.on('connect', onConnect);
        backendSocket.on('disconnect', onDisconnect);
        backendSocket.on('statistic/agents', onStatistic);

        return () => {
            backendSocket.off('connect', onConnect);
            backendSocket.off('disconnect', onDisconnect);
            backendSocket.off('statistic/agents', onStatistic);
        };
    }, []);


    return (
        <div className="available_statistic__wrapper">
            <div style={{ marginLeft: '200px', marginRight: '200px', display: 'flex' }}>
                <div>
                    <Table style={{ width: '800px', marginRight: '50px' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>General statistic</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>{ agentsStatisticFetchTime }</TableCell>
                            </TableRow>
                            {
                                agentsStatistic && <>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>Status</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Count</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>Online</TableCell>
                                        <TableCell>{ agentsStatistic.statistic.online }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>On call</TableCell>
                                        <TableCell>{ agentsStatistic.statistic.on_call }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>Break</TableCell>
                                        <TableCell>{ agentsStatistic.statistic.break }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>Lunch</TableCell>
                                        <TableCell>{ agentsStatistic.statistic.lunch }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>Meeting</TableCell>
                                        <TableCell >{ agentsStatistic.statistic.meeting }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>QA work</TableCell>
                                        <TableCell>{ agentsStatistic.statistic.qa_work }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>Admin work</TableCell>
                                        <TableCell>{ agentsStatistic.statistic.admin_work }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'left' }}>Training</TableCell>
                                        <TableCell>{ agentsStatistic.statistic.training }</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Agents</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>Username</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Call center</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                    </TableRow>
                                    {agentsStatistic.perUser.filter(agent => agent.status !== 'OFFLINE').map(agent => <TableRow key={agent.id}>
                                            <TableCell style={{ textAlign: 'left' }}>{ agent.username }</TableCell>
                                            <TableCell>{ agent.call_center }</TableCell>
                                            <TableCell>{ agent.status.toLowerCase().split("_").join(" ") }</TableCell>
                                    </TableRow>)}
                                </>
                            }
                            {! agentsStatistic && <TableRow>
                                <td colSpan={3} style={{ textAlign: "center" }}>Waiting for data...</td>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default connect(mapStateToProps)(AgentsStatistic);
