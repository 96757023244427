import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../Styles/AvailableLeadsPage.css";

const AgentsLimitationsTable = props => {
    const [ opened, setOpened ] = useState(false);
    const [ limitations, setLimitations ] = useState({});

    const updateLimitation = (index, value) => {
        const data = {
            ...limitations,
            [index]: Number(value) || 0
        };
        setLimitations(data);
        props.onChange({ target: { value: data } })
    }

    useEffect(() => {
        const data = {};
        for (let i = 0; i < 30; i++) {
            data[i] = props.value ? props.value[i] || 0 : 0;
        }
        setLimitations(data);
    }, []);

    return (
        <div className={"al-page"} style={{margin:'2rem 4rem', overflow:'auto', width: '100%'}}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <p className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                   style={{ marginBottom: '10px' }}
                >Agent limitations</p>
                <button className="agent-limitations-button"
                        onClick={() => setOpened(!opened)}
                >{opened ? "Close" : "Open"}</button>
            </div>
            {opened && <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Available headroom</TableCell>
                        <TableCell>Minimal agents count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(limitations).map(index => (
                            <TableRow key={index}>
                                <TableCell>{index}</TableCell>
                                <TableCell>
                                    <TextField
                                        onChange={({ target }) => updateLimitation(index, target.value)}
                                        type="text"
                                        value = {limitations[index]}
                                        inputMode='numeric'
                                        style={{ textAlign: 'center' }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table> }
        </div>
    )
}

export default AgentsLimitationsTable;
