import React, { useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';

const CSVDownloadButton = ({
                             withLocal= false,
                             withLongDistance= false,
                             rotation = null
}) => {

  const link = () => {
    const params = [];
    params.push(`withLocal=${withLocal ? 1 : 0}`);
    params.push(`withLongDistance=${withLongDistance ? 1 : 0}`);
    if (rotation !== null) {
      params.push(`rotation=${rotation}`);
    }
    return `/buffer/statistic/csv?${params.join("&")}`;
  }
  return (
    <a href={link()}>
        <CloudDownloadIcon color="success" />
    </a>
  );
};

export default CSVDownloadButton;
