import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? "https://buffer.leads.software" : 'http://localhost:3000';
const BACKEND_URL = process.env.NODE_ENV === 'production' ? "https://rabbit.dijoca.com" : 'https://rabbit.dijoca.com';

export const socket = io(URL, {
    path: "/buffer/socket.io"
});

export const backendSocket = io(BACKEND_URL, {
    path: "/socket.io"
});
