import './App.css';
import './Components/style.comp.css'
import React, {useEffect} from 'react';
import axios from "axios";
import FooterLeads from "./Components/FooterLeads";
import HeaderLeads from "./Components/HeaderLeads";
import {BrowserRouter,Route,Switch} from "react-router-dom";
import {connect} from 'react-redux';
import Actions from './Actions'
import Home from "./Components/Home";
import LeadsDetailsTable from "./Components/LeadsDetailsTable";
import Settings from "./Components/Settings";
import LeadsTable from "./Components/LeadsTable";
import Predictions from './Components/Predictions';
import AgentRotation from "./Components/AgentRotation";
import AgentAvgTimeToCall from "./Components/AgentAvgTimeToCall"
import CallStats from './Components/CallStats';
import PredictionsStatistic from "./Components/PredictionsStatistic";
import AvailabilityStatistic from "./Components/AvailabilityStatistic";
import AgentsStatistic from "./Components/AgentsStatistic";
import PredictionKPI from './Components/PredictionKPI';
import PredictionReport from "./Components/PredictionReport";

const mapStateToProps = (state) => {
  return{
    isFetching: state.data.isFetching,
    data: state.data?.data,
    recentLeads: state.recentLeads,
    nextLeads: state.nextLeads
  }
}

const App = (props) => {
  const {isFetching,dispatch} = props;
  const {setData, setRecentLeadsStatistic, setNextLeadsStatistic} = Actions;

  const fetchingData = () => {
      dispatch(setData({isFetching:true}));
  }

  const fetchData = async () => {
    let response = await axios.get("/buffer/status");
    // let recentLeads = await axios.get("/buffer/recentLeads");
    // let nextLeads = await axios.get("/buffer/nextLeads");
    dispatch(setData({data:response.data,isFetching:false}))
    // dispatch(setRecentLeadsStatistic({recentLeads: recentLeads}))
    // dispatch(setNextLeadsStatistic({nextLeads: nextLeads}))
  }

  useEffect(()=>{
      if(isFetching) {
          setTimeout(() => {
              fetchData()

          }, 1000)
      }
      // eslint-disable-next-line
  },[isFetching])

  useEffect(  () => {
    fetchData();
      // eslint-disable-next-line
  },[]);


  return (
    <BrowserRouter>
      <div>
        <HeaderLeads
            isFetching={isFetching}
            fetchingData={fetchingData}
        />

        <Switch>
            <Route exact path={'/'} component={Home}/>
            <Route path={'/available'} component={LeadsTable}/>
            <Route path={'/companyleads'} component={LeadsDetailsTable}/>
            <Route path={'/settings'} component={Settings}/>
            <Route path={'/predictions'} component={Predictions}/>
            <Route path={'/agent/rank'} component={AgentRotation}/>
            <Route path={'/agent/avg-duration'} component={AgentAvgTimeToCall}/>
            <Route path={'/press-one'} component={CallStats}/>
            <Route path={'/prediction-statistic'} component={PredictionsStatistic}/>
            <Route path={'/statistic/availability'} component={AvailabilityStatistic}/>
            <Route path={'/agents/statistic'} component={AgentsStatistic}/>
            <Route path={'/prediction-kpi'} component={PredictionKPI} />
            <Route path={'/prediction-report'} component={PredictionReport} />
        </Switch>

        <FooterLeads/>
      </div>
    </BrowserRouter>

  );
}


export default connect(mapStateToProps)(App);
