import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './style.comp.css';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import ChartJsForPrediction from "./ChartJsForPrediction";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}


const charts = [
    {
        name: "Calls",
        subtitle: "Amount of total calls made",
        property: "total_calls",
    },
    
    {
        name: "Transfers",
        subtitle: "Amount of total transfers",
        property: "total_transfers",
    }
];

const CallStatistics = (props) => {
    const {isFetching} = props;
    const [chartData, setChartData] = useState([]);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [ isLoading, setIsLoading ]= useState(false);

    const handleDayChange = (date) => {
        setDate(moment(date).format('YYYY-MM-DD'));
    };

    const fetchDataForSelectedDay = useCallback(async (soft = false) => {
        try {
            if (! soft) setIsLoading(true);
            const data = await axios
                .get(`/buffer/statistic/calls?date=${date}`)
                .then(({data}) => data || [])
                .catch(error => {
                    if (error.response && error.response.data && typeof error.response.data === 'object') {
                        let warn = "";
                        for (const key in error.response.data) {
                            const message = error.response.data[key];
                            warn += message;
                        }

                        toast.warn(warn);
                    }

                    return [];
                });

            setChartData(data.data);

            if (! soft) setIsLoading(false);
        } catch (error) {
            toast.error('Data not available');
            console.log(error);
        }

    }, [date, isFetching]);

    useEffect(() => {
        fetchDataForSelectedDay();
    }, [fetchDataForSelectedDay]);

    return (
        <div className="chart-container">
            <div className="previous_dates-container" style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: "20px",
                padding: "20px"
            }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        label="Select date"
                        value={date}
                        onChange={handleDayChange}
                        maxDate={new Date()}
                        format="YYYY-MM-DD"
                        clearable
                        autoOk
                    />
                </MuiPickersUtilsProvider>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {
                    isLoading ? <h2 style={{ margin: '0 auto'}}>Loading....</h2> :
                    (charts.map( chart =>

                        <ChartJsForPrediction
                            key={chart.property}
                            data={chartData}
                            name={chart.name}
                            subtitle={chart.subtitle}
                            property={chart.property}
                            callComponent={true}
                        />
                    ))
                }
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default connect(mapStateToProps)(CallStatistics);
