import React, { useEffect, useState } from "react";
import {connect} from 'react-redux'
import Table from "@material-ui/core/Table";
import { TableCell, TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import "../Styles/AvailableLeadsStatisticTable.css";
import axios from "axios";
import { Link } from "react-router-dom";
import CSVDownloadButton from "./DownloadAvailableLeadStats";

const MapStateToProps = (state) => {
    return{
        moveType:state.checkBox.moveTypeAvailableTable,
        data:state.data.data
    }
}

const AvailableLeadsStatisticTable = () => {
    const [ statistic, setStatistic ] = useState(null);
    const [ expandedIndex, setExpandedIndex ] = useState(null);
    const [ rangeStart, setRangeStart ] = useState(null);
    const [ rangeEnd, setRangeEnd ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ withLocal, setWithLocal ] = useState(false);
    const [ withLongDistance, setWithLongDistance ] = useState(true);

    const expand = index => setExpandedIndex(expandedIndex === index ? null : index);

    const getFilters = () => {
        const params = [
            `withLocal=${Number(withLocal)}`,
            `withLongDistance=${Number(withLongDistance)}`,
            rangeStart ? `rangeStart=${rangeStart}` : null,
            rangeEnd ? `rangeEnd=${rangeEnd}` : null,
        ];

        return params.filter( item => item !== null ).join("&");
    }
    const reload = async () => {
        setLoading(true);
        let url = `/buffer/statistic/available-leads?${getFilters()}`;

        const data = await axios
            .get(url)
            .then( ({ data }) => data)
            .catch( e => {
                console.log(e);
                return null;
            });
            
        setStatistic(data);
        setLoading( false )
    }

    useEffect(async () => {
        await reload();
    }, []);

    useEffect(async () => {
        await reload();
    }, [ withLocal, withLongDistance ]);

    return (
        <div className="al-statistic-table">
            <div className="table-info">
                <div className="checkboxes">
                    <div className="checkbox">
                        <label className="checkbox-label">
                            <input type="checkbox"
                                   className="checkbox-input"
                                   value="local"
                                   disabled={loading}
                                   checked={withLocal}
                                   onChange={ _ => setWithLocal(!withLocal) }
                            />
                            <span className="checkbox-icon"></span>
                            <span className="checkbox-text">Local</span>
                        </label>
                    </div>
                    <div className="checkbox">
                        <label className="checkbox-label">
                            <input type="checkbox"
                                   className="checkbox-input"
                                   value="long_distance"
                                   disabled={loading}
                                   checked={withLongDistance}
                                   onChange={ _ => setWithLongDistance(!withLongDistance) }
                            />
                            <span className="checkbox-icon"></span>
                            <span className="checkbox-text">Long distance</span>
                        </label>
                    </div>
                </div>
            </div>
            <h3>Leads <span className="description">(leads count / predicted transfers)</span></h3>
            <div className="table-wrapper">
                {
                    statistic && <>
                        <div className="legend">
                            <div className="legend_item call-count">Call count</div>
                            <div className="legend_item match-count">Match count</div>
                        </div>
                    </>
                }
                {
                    ! statistic && <>
                        <div className="loading">
                            Loading...
                        </div>
                    </>
                }
                <Table>
                    {
                        statistic && <>
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    {
                                        statistic.groups.map( group => {
                                            if (group !== 'Custom range') {
                                                return (
                                                    <TableCell>{ group }</TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell className="custom-range">
                                                        <div className="placeholder">Custom range</div>
                                                        <div className="inputs">
                                                            <input type="number"
                                                                   placeholder="Start"
                                                                   min="0"
                                                                   tabIndex={1}
                                                                   value={rangeStart}
                                                                   onInput={e => setRangeStart(e.target.value)}
                                                                   onKeyUp={e => e.code.toLowerCase() === "enter" ? reload() : null}
                                                            />
                                                            <input type="number"
                                                                   placeholder="End"
                                                                   min="0"
                                                                   tabIndex={2}
                                                                   value={rangeEnd}
                                                                   onInput={e => setRangeEnd(e.target.value)}
                                                                   onKeyUp={e => e.code.toLowerCase() === "enter" ? reload() : null}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                )
                                            }
                                        })
                                    }
                                    <TableCell>Total</TableCell>
                                    <TableCell>Download CSV</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                loading && <>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={Object.values(statistic.totals.group).length + 2}>Loading...</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </>
                            }
                            {
                                ! loading && <>
                                    <TableBody>
                                        {
                                            statistic.data.byCallCount.map( (byCallCount, callCountIndex) => (
                                                <>
                                                    <TableRow>
                                                        <TableCell className="call-count"
                                                                   onClick={() => expand(callCountIndex)}
                                                        >{callCountIndex + 1} {expandedIndex === callCountIndex ? "-" : "+"}</TableCell>
                                                        {
                                                            Object.values(statistic.totals.callCountAndGroup[callCountIndex]).map( (total, groupIndex) => {
                                                                const group = Object.keys(statistic.totals.callCountAndGroup[callCountIndex])[groupIndex];
                                                                return (
                                                                    <TableCell>
                                                                        <Link target="_blank" to={{ pathname: `/available?group=${group}&callCount=${callCountIndex}&${getFilters()}` }}>
                                                                            {total.count}/{total.predictedTransfers}
                                                                        </Link>
                                                                    </TableCell>
                                                                )
                                                            })
                                                        }
                                                        <TableCell>
                                                            <Link target="_blank" to={{ pathname: `/available?callCount=${callCountIndex}&${getFilters()}` }}>
                                                                {statistic.totals.callCount[callCountIndex].count}/{statistic.totals.callCount[callCountIndex].predictedTransfers}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>
                                                        <CSVDownloadButton withLocal={withLocal}
                                                                           withLongDistance={withLongDistance}
                                                                           rotation={callCountIndex}
                                                        />
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        expandedIndex === callCountIndex && byCallCount.byMatchCount.map( (byMatchCount, matchCountIndex) => (
                                                            <TableRow>
                                                                <TableCell className="match-count">{ matchCountIndex }</TableCell>
                                                                {
                                                                    Object.values(byMatchCount.byGroup).map( (byGroup, groupIndex) => {
                                                                        const group = Object.keys(byMatchCount.byGroup)[groupIndex];
                                                                        return (
                                                                            <TableCell>
                                                                                <Link target="_blank" to={{ pathname: `/available?group=${group}&callCount=${callCountIndex}&matchCount=${matchCountIndex}&${getFilters()}` }}>
                                                                                    {byGroup.count}/{byGroup.predictedTransfers}
                                                                                </Link>
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                                <TableCell>
                                                                    <Link target="_blank" to={{ pathname: `/available?callCount=${callCountIndex}&matchCount=${matchCountIndex}&${getFilters()}` }}>
                                                                        {statistic.totals.callCountAndMatchCount[callCountIndex][matchCountIndex].count}/
                                                                        {statistic.totals.callCountAndMatchCount[callCountIndex][matchCountIndex].predictedTransfers}
                                                                    </Link>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </>
                                            ))
                                        }
                                        <TableRow>
                                            <TableCell>Total</TableCell>
                                            {
                                                Object.values(statistic.totals.group).map( (byGroup, groupIndex) => {
                                                    const group = Object.keys(statistic.totals.group)[groupIndex];

                                                    return (
                                                        <TableCell>
                                                            <Link target="_blank" to={{ pathname: `/available?group=${group}&${getFilters()}` }}>
                                                                {byGroup.count}/{byGroup.predictedTransfers}
                                                            </Link>
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                            <TableCell>
                                                <Link target="_blank" to={{ pathname: `/available?${getFilters()}` }}>
                                                    {statistic.totals.general.count}/{statistic.totals.general.predictedTransfers}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                            <CSVDownloadButton withLocal={withLocal}
                                                               withLongDistance={withLongDistance}
                                            />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </>
                            }
                        </>
                    }
                </Table>
            </div>
        </div>

    )
}

export default connect(MapStateToProps)(AvailableLeadsStatisticTable);