import axios from "axios";
import React, { useEffect, useState } from 'react';
import './style.comp.css';
import {ToastContainer, toast} from 'react-toastify';
import {connect} from "react-redux";
import {
    Checkbox,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead, TextField
} from "@material-ui/core";

const mapStateToProps = (state) => {
    return {
        isFetching: state.data.isFetching,
    }
}

const AgentAvgTimeToCall = (props) => {
    const {isFetching} = props;
    const [ agentsAvgTime, setAgentsAvgTime ] = useState([]);
    const [ showAvailableOnly, setShowAvailableOnly ] = useState(false);
    const [ order, setOrder ] = useState({ field: "avg_time_to_call", asc: "desc" });
    const [ loading, setLoading ] = useState(false);
    const [ refreshInterval, setRefreshInterval ] = useState(null);

    const fetchAgentAvgTimeToCall = async () => {
        setLoading(true);
        const agentsAvgTime = await axios
            .get(`/buffer/agent/avg-duration?orderBy=${order.field}&orderAsc=${order.asc}&availableOnly=${showAvailableOnly ? 1 : 0}`)
            .then( ({ data }) => data)
            .catch( () => []);

        setAgentsAvgTime(agentsAvgTime);
        setLoading(false);
    }

    const changeOrdering = field => {
        if (loading) return;

        if (order.field === field) {
            setOrder({ field, asc: order.asc === "asc" ? "desc": "asc" })
            return;
        }

        setOrder({ field, asc: "asc" });
    }
    const clearRefresh = () => {
        if (! refreshInterval) return;

        clearInterval(refreshInterval);
        setRefreshInterval(null);
    }
    const startRefresh = () => setRefreshInterval(setInterval(fetchAgentAvgTimeToCall, 5_000));
    useEffect(async() => {
        clearRefresh();
        await fetchAgentAvgTimeToCall();
        startRefresh(isFetching, showAvailableOnly, order);
    }, [ isFetching, showAvailableOnly, order ]);

    return (
        <div className="agent-avg-time-to-call">
            <div style={{ maxWidth: '1520px', paddingTop:'2rem', fontSize:'1.5rem', display:'flex', justifyContent:'space-between', alignItems: "center", margin: 'auto'}} >
                <div>
                    <h2>Agents average time to call <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>{refreshInterval ? '(Auto refresh)' : ''}</span></h2>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                            Show available only
                            <Checkbox onChange={ (e) => setShowAvailableOnly(! showAvailableOnly) }
                                      checked={ showAvailableOnly }
                                      style={{ color: '#07b839' }}
                            />
                        </label>
                    </div>
                </div>
            </div>

            <div className="call-timeouts-table" style={{ maxWidth: '1600px', padding:'2rem', fontSize:'1.5rem', display:'flex', justifyContent:'center', margin: 'auto'}} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', userSelect: "none" }}>
                                    Index
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("id") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'id' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'id' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    ID
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("name") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'name' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'name' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Agent name
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("call_center") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'call_center' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'call_center' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Call center
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("online") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'online' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'online' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Online
                                </div>
                            </TableCell>
                            <TableCell onClick={ () => changeOrdering("on_call") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'on_call' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'on_call' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    On call
                                </div>
                            </TableCell>
                            <TableCell style={{ width: "150px" }} onClick={ () => changeOrdering("count_of_calls") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'count_of_calls' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'count_of_calls' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Count of calls
                                </div>
                            </TableCell>
                            <TableCell style={{ width: "150px" }} onClick={ () => changeOrdering("avg_time_to_call") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'avg_time_to_call' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'avg_time_to_call' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Average time (seconds)
                                </div>
                            </TableCell>
                            <TableCell style={{ width: "150px" }} onClick={ () => changeOrdering("max_time") }>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 'bold', cursor: "pointer", userSelect: "none" }}>
                                    <div className="ordering">
                                        <div className={ `arrow-up ${order.field === 'max_time' && order.asc === "asc" ? "active" : ""}`}></div>
                                        <div className={ `arrow-down ${order.field === 'max_time' && order.asc === "desc" ? "active" : ""}`}></div>
                                    </div>
                                    Max time (seconds)
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agentsAvgTime.map( (agentAvgTime, index) => (
                            <TableRow key={agentAvgTime.id}>
                                <TableCell style={{ textAlign: "center" }}>{index + 1}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentAvgTime.id}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentAvgTime.name}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentAvgTime.call_center}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentAvgTime.online ? "Yes" : "No"}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentAvgTime.on_call ? "Yes" : "No"}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentAvgTime.count_of_calls}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentAvgTime.avg_time_to_call}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{agentAvgTime.max_time}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default connect(mapStateToProps)(AgentAvgTimeToCall);
